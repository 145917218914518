import consumer from "./consumer"

//consumer.subscriptions.create("ChatChannel", {
const chatChannel = consumer.subscriptions.create("ChatChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },
/*
  received(data) {
    const html = `<div class="chat self"><div class="chat-body">${data.content.text}</div></div>`;
    const chats = document.getElementById('chats');
    const newchat = document.getElementById('chat_text');
    chats.insertAdjacentHTML('afterend', html);   
    newchat.value = '';
    //chats.classList.add("big_font");
    //chats.insertAdjacentHTML('afterbegin', imageHtml);
  }
});
*/
  received(data) {
    // 画面を開いているのがチャット送信者だった場合
    //if (data["isCurrent_user"] == true) {
    if (data["text"] != null) {
     var content = data["text"];
    } else if(data["image"] != null) {
     var content = `
      <a href="#" onclick="location.reload()">
        <i class="fas fa-image fa-3x"></i>
        <br>
        <b>Open New Image</b>
      </a>
     `;
    }

    if (data["isCurrent_user"] == true) {
     var chat_text = `
      <div class="chat self">
        <div class="nav_link">
        </div>
        <div class="chat-sender"></div>
        <div class="chat-body">
          ${isUrl(content) ? `<a href="${content}" target="_blank">${content}</a>` : content}
        </div>
        <div class="time right">
            ${data["time_current"]}
        </div>
      </div>`;
    //} else {
    } else {
      var chat_text = `
      <div class="chat other">
        <div class="chat-sender">
          <div class="nav_link">
            <i class="fas fa-user-circle fa-3x"></i>
          </div>
        </div>
        <div class="chat-body">
          ${isUrl(content) ? `<a href="${content}" target="_blank">${content}</a>` : content}
        </div>
        <div class="time left">
            ${data["time_current"]}
        </div>
      </div>`;
    }
    $("#chats").append(chat_text);

    const room = document.getElementById(data["room_uuid"]); // 仮のID
    //const oldChatCreatedAt = room.querySelector('.chat_created_at');

    if(data["text"]){
       var content = data["text"]
    } else {
       var content = 'New Image'
    }
    if (room){
      const newContent = `
      <div class="chat_content">
        <p>
          <span>${content}</span>
        </p>
        <div class="chat_created_at">
          ${data.time_current}
        </div>
      </div>
      <div class="status available"></div>
      `;

      const oldContent = room.querySelector('.chat_content');
      if (oldContent) {
        oldContent.innerHTML = newContent;
      } else {
        console.error('oldContentが見つかりません: .chat_content');
      }
    } else {
      //Volunteer.createに連動
      const newContent = `
      <a data-turbolinks="false" href="/rooms/${data["room_uuid"]}">
        <div id="${data["room_uuid"]}">
          <div class="friend">
            <i class="friend_img fas fa-user-circle fa-3x"></i>
            <p>
              <strong>
                No name
              </strong>
            </p>

            <div id="<%= r.id %>">
              <div class="chat_content">
                <p>
                  <span>${data["text"].innerHTML}</span>
                </p>
                <div class="chat_created_at">
                  ${data['time_current']}
                </div>
              </div>
              <div class="status available"></div>
            </div>
          </div>
        </div>
      </a>
      `;
      $("#friendslist").prepend(newContent);  
    }

/*
    $("#friends").prepend(room_text);
    const room = document.getElementById(data.room_uuid);
    const newContent = document.createElement('div');
    newContent.innerHTML = room_text;
    const oldContent = room.firstChild;
    room.replaceChild(newContent, oldContent);
    room.append(room_text);
    room.replaceWith(room_text);
    document.getElementById(data.room_uuid).classList.replace(room_text);
    room = document.getElementById(data.room_uuid)
    oom.replace(room_text);
*/

  //$("#chats").append(chat_image);
    var element = document.getElementById("scrollToBottom");
    element.scrollIntoView({ behavior: "smooth" });
    //document.getElementById('chats').insertAdjacentHTML('afterend', chat_text);   
  

  },
/*
  speak(data) {
    //return this.perform('speak', {message: content});
    const current_user_id = $("#current_user_id").val();
    const partner_id = $("#partner_id").val();
    const text = $("#chat_text").val();
    const room_id = $("#room_id").val();
    const room_uuid = $("#room_uuid").val();
    const image = $("#chat_image").val();
    const time_current = $("#time_current").val();
    return this.perform("speak", {
      text: text,
      current_user_id: current_user_id,
      partner_id: partner_id,
      room_id: room_id,
      room_uuid: room_uuid,
      image: image,
      time_current: time_current
    });
  },
  */
  speak: function(data) {
    const current_user_id = $("#current_user_id").val();
    const partner_id = $("#partner_id").val();
    const text = $("#text").val();
    const room_id = $("#room_id").val();
    const room_uuid = $("#room_uuid").val();
    const image = $("image").val();
    const time_current = $("#time_current").val();
    return this.perform('speak', {
      text: text,
      current_user_id: current_user_id,
      partner_id: partner_id,
      room_id: room_id,
      room_uuid: room_uuid,
      image: image,
      time_current: time_current
    });
  }
/*
  speak: function(content) {
    return this.perform('speak', {content: content});
  }
*/

});
/*
$(function () {
  $("#chat_register_button").on("click", function (e) {
    var chat_text = $("#chat_text").val();
    App.room.speak(chat_text);
    $("#chat_text").val(""); // フォームを空に
    e.preventDefault();
  });
});
*/

function isUrl(str) {
    // URLの形式に合致するかチェックする正規表現
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    return regex.test(str);
}
/*
//エンターキーで送信*フォームがtext_fieldで有効（text_areaでは改行するため）
$(document).on('keypress', '[data-behavior~=room_speaker]', function(event) {
  if (event.keyCode === 13) {
    chatChannel.speak(event.target.value);
    event.target.value = '';
    return event.preventDefault();
  }
});
*/
//送信ボタンで送信
document.addEventListener('DOMContentLoaded', function(){
  var text = document.getElementById('text')
  var button = document.getElementById('chat_register_button')
  button.addEventListener('click', function() {
      var content = text;
      chatChannel.speak(content);
      text.value = '';
  });
})