import consumer from "./consumer"

consumer.subscriptions.create("VolunteerChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    document.getElementById(data.request_uuid).classList.add("disable_btn");
  }
});